import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MyTel } from '../common/controls/phone-control/phone-control.component';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../dailog/dialog-with-message/dialog-with-message.component';
import {
  AddressDetail,
  PersonalDetail,
  User,
} from '../_models/classes/User.model';
import {
  UserDetail,
  UserLookupRequest,
} from '../_models/registration/UserDetail';
import { UserdisclosureService } from '../_services/disclosure/user-disclosure.service';
import { IdaQuizService } from '../_services/registration/ida-quiz.service';
import { IdpService } from '../_services/registration/idp.service';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { userinformation } from '../_models/classes/user-information.model';
import { IdpUpdateUser } from '../_models/registration/IdpUser';
import { ManageFreezeService } from '../_services/manage-freeze/manage-freeze.service';
import { freezeExists } from '../_models/classes/freeze-exists.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@ruf/idp-auth';
import { DashboardService } from '../_services/dashboard-services/dashboard.service';
import { GlobalVariables } from '../common/enums/global_variables';
import moment from 'moment';
import { environment } from '../../environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'chexsystem-ws-disclosure',
  templateUrl: './disclosure.component.html',
  styleUrls: ['./disclosure.component.scss'],
})
export class DisclosureComponent implements OnInit {
  userDetail: User;
  isReportShown: boolean = false;
  dialogWidth: string = '667px';
  homeChexSystemsUrl: string = 'https://www.chexsystems.com';
  userId: string;
  userDisclosureDetail: UserDetail;
  mobilePhone: string;
  eyeIconTypeSSN: string = 'eye';
  eyeIconTooltipSSN: string = 'show';
  ssnNo: string;
  ssnNoMask: string;
  ssnNoDisplay: string;
  userInformation: userinformation.UserInformationRoot =
    new userinformation.UserInformationRoot();
  isMandatoryFieldsEmpty: boolean = false;
  title = this._translate.instant('DISCLOSURE.TITLE');
  info1 = this._translate.instant('DISCLOSURE.INFO1');
  firstname = this._translate.instant('DISCLOSURE.FIRSTNAME');
  middlename = this._translate.instant('DISCLOSURE.MIDDLENAME');
  lastname = this._translate.instant('DISCLOSURE.LASTNAME');
  suffix = this._translate.instant('DISCLOSURE.SUFFIX');
  dob = this._translate.instant('DISCLOSURE.DOB');
  ssnum = this._translate.instant('DISCLOSURE.USSOCIALSECURITYNUM');
  dlnum = this._translate.instant('DISCLOSURE.DLNUMBER');
  stateofinsurance = this._translate.instant('DISCLOSURE.STATEOFISSURANCE');
  email = this._translate.instant('DISCLOSURE.EMAIL');
  phnnum = this._translate.instant('DISCLOSURE.PHONENUMBER');
  currentaddr1 = this._translate.instant('DISCLOSURE.CURRENTADDRESSLINE1');
  currentaddr2 = this._translate.instant('DISCLOSURE.CURRENTADDRESSLINE2');
  city = this._translate.instant('DISCLOSURE.CITY');
  state = this._translate.instant('DISCLOSURE.STATE');
  zipcode = this._translate.instant('DISCLOSURE.ZIPCODE');
  confirm = this._translate.instant('BUTTONS.CONFIRM');
  editprofile = this._translate.instant('BUTTONS.EDITPROFILE');
  info2 = this._translate.instant('DISCLOSURE.INFO2');
  info3 = this._translate.instant('DISCLOSURE.INFO3');
  isFirstNameMandatory: boolean = false;
  isLastNameMandatory: boolean = false;
  isEmailAddrMandatory: boolean = false;
  isGovtNbrMandatory: boolean = false;
  isBirthDtMandatory: boolean = false;
  isAddrLine1Mandatory: boolean = false;
  isCityNmMandatory: boolean = false;
  isStatCodeMandatory: boolean = false;
  isZipCodeMandatory: boolean = false;
  isPhoneMobileMandatory: boolean = false;
  constructor(
    public dialog: MatDialog,
    private disclosureService: UserdisclosureService,
    private router: Router,
    private idpService: IdpService,
    private idaQuizService: IdaQuizService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private piConfirmService: PersonalInformationConfirmationService,
    private manageFreezeService: ManageFreezeService,
    private _translate: TranslateService,
    private auth: AuthService,
    private dashboardService: DashboardService
  ) {
    this.userDisclosureDetail = {
      UserLookupRsp: {
        CnsmrAddrDetls: {
          CnsmrAddr: {
            AddrLine1: '',
            AddrLine2: '',
            CityNm: '',
            StatCode: '',
            ZipCode: '',
          },
        },
        CnsmrPrsnlDetls: {
          BirthDt: '',
          CnsmrDrivLicenseDetls: { DrivLicenseIssuSt: '', DrivLicenseNbr: '' },
          CnsmrNmDetls: {
            FrstNm: '',
            LastNm: '',
            MidNm: '',
            SuffixNm: '',
            PreviousLastNm: '',
          },
          EmailAddr: '',
          GovtNbr: '',
          PhoneNbr: '',
          CellPhoneNbr: '',
          CorrespondenceDlvryPref: '',
        },
        UserName: '',
        Cid: '',
        Code: '',
        Message: '',
        Id: '',
      },
    };
  }

  async isProtectConsumer() {
    let retResult: boolean = false;
    let feRequestRoot: freezeExists.FreezeExistsRequestRoot =
      new freezeExists.FreezeExistsRequestRoot();
    var _userName = GlobalVariables.globalvars.get('UserId') || '';
    // let capchaToken: any; 
    // try {
    //   capchaToken = await this.recaptchaV3Service.execute('submit').toPromise();
    // }  catch (error) {}

    let lookupRequest: UserLookupRequest = new UserLookupRequest();
    lookupRequest.UserLookupReq = { UserName: _userName, Cid: '' };
    this.idaQuizService
      .getUserDetailFromDecisionSolution(lookupRequest)
      .subscribe((res: any) => {
        let usSSNumber = res.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr;
        feRequestRoot.GovtNbr = usSSNumber;
        feRequestRoot.UserName = _userName;
       
        // feRequestRoot.RecapchaToken = capchaToken;
        this.manageFreezeService
          .SrchFrzExistence(feRequestRoot)
          .subscribe((feRes) => {
            if (feRes.FreezeExists && feRes.ProtectedFlagInd === 'Y') {
              const dialogMessage = new DialogMessage(
                this._translate.instant(
                  'MANAGESECURITYFREEZE.PROTECTEDCONSUMERTEXT'
                ),
                this._translate.instant('BUTTONS.OK'),
                false,
                this._translate.instant(
                  'MANAGESECURITYFREEZE.PROTECTEDCONSUMERTITLE'
                )
              );
              const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
                panelClass: ['fis-style', 'custom-dialog-without-close'],
                data: dialogMessage,
                disableClose: true,
              });
              dialogRef2.afterClosed().subscribe((result) => {
                retResult = true;

                GlobalVariables.globalvars.clear();
                localStorage.clear();
                sessionStorage.removeItem('ProfileConfirmed');
                let qp = moment().unix();
                window.location.href =
                  environment.globalLogoutUrl +
                  environment.auth.clientId +
                  '&random=' +
                  qp;
              });
             } 
            //else  if(res?.UserLookupRsp && res?.UserLookupRsp?.SplHndlInd === 'Y'){
            //   const dialogMessage = new DialogMessage(
            //     this._translate.instant(
            //       'USERPROFILE.SPLHANDLINGCNSMRTEXT'
            //     ),
            //     this._translate.instant('BUTTONS.OK'),
            //     false,
            //     this._translate.instant(
            //       'USERPROFILE.SPLHANDLINGCNSMRTITLE'
            //     )
            //   );
            //   const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
            //     panelClass: ['fis-style', 'custom-dialog-without-close'],
            //     data: dialogMessage,
            //     disableClose: true,
            //   });
            //   dialogRef2.afterClosed().subscribe((result) => {
            //     retResult = true;
    
            //     GlobalVariables.globalvars.clear();
            //     localStorage.clear();
            //     sessionStorage.removeItem('ProfileConfirmed');
            //     let qp = moment().unix();
            //     window.location.href =
            //       environment.globalLogoutUrl +
            //       environment.auth.clientId +
            //       '&random=' +
            //       qp;
            //   });
            // }
            else {
              this.onInitHandler();
            }
          });
      });
    return retResult;
  }

  onInitHandler() {
    const isEmptyString = (data: string): boolean =>
      typeof data === 'string' && data.trim().length == 0;
    // get user detail by username
    if (sessionStorage.getItem('ProfileConfirmed') == 'confirmed') {
      this.router.navigate(['dashboard']);
    } else {
      this.piConfirmService.piUnConfirmed();
      var _userName = GlobalVariables.globalvars.get('UserId') || '';
      let lookupRequest: UserLookupRequest = new UserLookupRequest();
      lookupRequest.UserLookupReq = { UserName: _userName, Cid: '' };
      this.idaQuizService
        .getUserDetailFromDecisionSolution(lookupRequest)
        .subscribe(
          (res) => {
            this.userDisclosureDetail = res;
            this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
            /////////////////////SuffixNm
            //alert(this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls);
            this.userInformation.CnsmrPrsnlDetls =
              new userinformation.CnsmrPrsnlDetls();
            this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls =
              new userinformation.CnsmrDrivLicenseDetls();
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls =
              new userinformation.CnsmrNmDetls();
            if (
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls
                ?.CnsmrDrivLicenseDetls
            ) {
              this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt =
                this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls?.CnsmrDrivLicenseDetls?.DrivLicenseIssuSt;
              this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr =
                this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls?.CnsmrDrivLicenseDetls?.DrivLicenseNbr;
            } else {
              this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt =
                '';
              this.userInformation.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr =
                '';
            }
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm;
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm;
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm;
            this.userInformation.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastNm;
            this.userInformation.CnsmrPrsnlDetls.GovtNbr =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr;
            //this.userInformation.CnsmrPrsnlDetls.GovtNbr = "491713274";//Harsh
            //this.userInformation.CnsmrPrsnlDetls.PhoneNbr = this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr;
            this.userInformation.CnsmrPrsnlDetls.BirthDt =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.BirthDt;
            this.userInformation.CnsmrAddrDetls =
              this.userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls;
            //this.userInformation.CellPhoneNbr = this.ConvertPhoneFormate(this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr);
            this.userInformation.UserName = _userName;
            this.userInformation.UserID =
              this.userDisclosureDetail.UserLookupRsp.Id;
            this.userInformation.CorrespondenceDeliveryPreference =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.CorrespondenceDlvryPref;
            this.userInformation.NotificationEmailAddr =
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.EmailAddr;
            //localStorage.setItem("UserInformation", JSON.stringify(this.userInformation));
            //////////////////////
            let usSSNumber = this.ConvertUSSSNFormate(
              res.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr
            );
            this.ssnNo =
              usSSNumber.area +
              '-' +
              usSSNumber.exchange +
              '-' +
              usSSNumber.subscriber;
            this.ssnNoDisplay = this.ssnNoMask =
              '***-**-' + usSSNumber.subscriber;
            let cellPhoneNumber: MyTel = new MyTel('', '', '');
            let phoneType: string = '';
            if (res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr.trim() !== '') {
              phoneType = 'Mobile';
              cellPhoneNumber = this.ConvertPhoneFormate(
                res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr
              );
            } else if (res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr !== '') {
              phoneType = 'Phone';
              cellPhoneNumber = this.ConvertPhoneFormate(
                res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr
              );
            }
            let cellularPhoneFormat: MyTel = new MyTel('', '', '');
            let homePhoneFormat: MyTel = new MyTel('', '', '');
            let cellularPhone: string = '';
            let homePhone: string = '';
            if (res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr.trim() !== '') {
              cellularPhoneFormat = this.ConvertPhoneFormate(
                res.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr
              );
              cellularPhone =
                cellularPhoneFormat.area +
                cellularPhoneFormat.exchange +
                cellularPhoneFormat.subscriber;
            }
            if (res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr.trim() !== '') {
              homePhoneFormat = this.ConvertPhoneFormate(
                res.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr
              );
              homePhone =
                homePhoneFormat.area +
                homePhoneFormat.exchange +
                homePhoneFormat.subscriber;
            }
            this.mobilePhone =
              phoneType +
              ' - ' +
              cellPhoneNumber.area +
              cellPhoneNumber.exchange +
              cellPhoneNumber.subscriber;
            this.userInformation.CellPhoneNbr = cellularPhone;
            this.userInformation.CnsmrPrsnlDetls.PhoneNbr = homePhone;
            // localStorage.setItem(
            //   'UserInformation',
            //   JSON.stringify(this.userInformation)
            // );

            if (GlobalVariables.globalvars.has('UserInformation')) {
              GlobalVariables.globalvars.delete('UserInformation');
            }
            GlobalVariables.globalvars.set(
              'UserInformation',
              JSON.stringify(this.userInformation)
            );

            // Disable Confirm button if  mandatory fields is empty
            if (
              this.userDisclosureDetail != null &&
              this.userDisclosureDetail.UserLookupRsp != null &&
              this.userDisclosureDetail.UserLookupRsp != undefined
            ) {
              this.isMandatoryFieldsEmpty = this.checkMandatoryFields(
                this.userDisclosureDetail
              );
            }
            // Mandatory flag set
            this.isFirstNameMandatory = isEmptyString(
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls
                .CnsmrNmDetls.FrstNm
            );
            this.isLastNameMandatory = isEmptyString(
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls
                .CnsmrNmDetls.LastNm
            );
            this.isEmailAddrMandatory = isEmptyString(
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.EmailAddr
            );
            this.isPhoneMobileMandatory = isEmptyString(this.mobilePhone);
            this.isGovtNbrMandatory = isEmptyString(
              res.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr
            );
            this.isBirthDtMandatory = isEmptyString(
              this.userDisclosureDetail.UserLookupRsp.CnsmrPrsnlDetls.BirthDt
            );
            this.isAddrLine1Mandatory = isEmptyString(
              this.userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr
                .AddrLine1
            );
            this.isCityNmMandatory = isEmptyString(
              this.userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr
                .CityNm
            );
            this.isStatCodeMandatory = isEmptyString(
              this.userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr
                .StatCode
            );
            this.isZipCodeMandatory = isEmptyString(
              this.userDisclosureDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr
                .ZipCode
            );
          },
          (error) => {},
          () => {}
        );
    }
  }
  ngOnInit(): void {
    this.isProtectConsumer();
  }

  navigateToTermsandConditions(event: any) {
    const dialogMessage = new DialogMessage('', '', true);
    dialogMessage.messagetext = 'Terms and Conditions';
    dialogMessage.buttontext = 'OK';
    dialogMessage.title = 'Terms and Conditions';
    const dialogRef2 = this.dialog.open(DialogwithmessageComponent, {
      width: this.dialogWidth,
      panelClass: ['fis-style', 'custom-dialog'],
      data: dialogMessage,
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if ((result as string) == '' || (result as string) == 'OK') {
        window.location.href = this.homeChexSystemsUrl + '#head';
      }
    });
  }

  editUser(event: any) {
    this.router.navigate(['userprofile']);
  }

  generateDisclosureReport() {
    // Call IDP update to sync with Decision data
    let userDetail = this.prepareIdpUserUpdateRequest(this.userInformation);
    this.idpService.UpdateUser(userDetail).subscribe(
      (updateResponse) => {
        this.piConfirmService.piConfirmed();
        this.router.navigate(['dashboard']);
      },
      (error) => {},
      () => {}
    );
  }

  private ConvertPhoneFormate(phoneNumber: string): MyTel {
    phoneNumber = phoneNumber
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '$1,$2,$3');
    let formatePhoneNumber = phoneNumber.split(',', 3);
    return new MyTel(
      formatePhoneNumber[0],
      formatePhoneNumber[1],
      formatePhoneNumber[2]
    );
  }

  private ConvertUSSSNFormate(phoneNumber: string): MyTel {
    phoneNumber = phoneNumber
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{2})(\d{4})/, '$1,$2,$3');
    let formatedPhoneNumber = phoneNumber.split(',', 3);
    return new MyTel(
      formatedPhoneNumber[0],
      formatedPhoneNumber[1],
      formatedPhoneNumber[2]
    );
  }

  _handleMasking(code: string, control4: any) {
    if (code === 'ssn') {
      if (this.eyeIconTypeSSN === 'eye') {
        this.eyeIconTypeSSN = 'eye-hide';
        this.eyeIconTooltipSSN = 'hide';
        this.ssnNoDisplay = this.ssnNo;
      } else if (this.eyeIconTypeSSN === 'eye-hide') {
        this.eyeIconTypeSSN = 'eye';
        this.eyeIconTooltipSSN = 'show';
        this.ssnNoDisplay = this.ssnNoMask;
      }
    }
  }

  /**
   * This function used to validate the user detail mandatory fields
   * @param userDetail
   * @returns true: some mandatory fields has empty, false: mandatory fields got valid value.
   */
  private checkMandatoryFields(userDetail: UserDetail): boolean {
    let communicationNumber =
      userDetail.UserLookupRsp.CnsmrPrsnlDetls.PhoneNbr.trim() ||
      userDetail.UserLookupRsp.CnsmrPrsnlDetls.CellPhoneNbr.trim();
    const validateUserDetail = [
      userDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim(),
      userDetail.UserLookupRsp.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim(),
      userDetail.UserLookupRsp.CnsmrPrsnlDetls.EmailAddr.trim(),
      communicationNumber,
      userDetail.UserLookupRsp.CnsmrPrsnlDetls.GovtNbr.trim(),
      userDetail.UserLookupRsp.CnsmrPrsnlDetls.BirthDt.trim(),
      userDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim(),
      userDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.CityNm.trim(),
      userDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.StatCode.trim(),
      userDetail.UserLookupRsp.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim(),
    ];
    return validateUserDetail.some((x) => x == '');
  }

  /**
   * This function used to generate IDP user detail for update
   * @param userDetail User detail
   * @returns IDP user update request detail
   */
  private prepareIdpUserUpdateRequest(
    userDetail: userinformation.UserInformationRoot
  ): IdpUpdateUser {
    const isEmptyString = (data: string): boolean =>
      typeof data === 'string' && data.trim().length == 0;
    let userUpdateRequestDetail: IdpUpdateUser = {
      firstName: userDetail.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim(),
      middleName: userDetail.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim(),
      lastName: userDetail.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim(),
      phone: !isEmptyString(userDetail.CnsmrPrsnlDetls.PhoneNbr)
        ? '+1' + userDetail.CnsmrPrsnlDetls.PhoneNbr
        : '',
      emailAddress: userDetail.NotificationEmailAddr.trim(),
      mobile: !isEmptyString(userDetail.CellPhoneNbr)
        ? '+1' + userDetail.CellPhoneNbr
        : '',
      loginName: userDetail.UserName.split("'").join("''"), // to validate single quote and append '
    };
    return userUpdateRequestDetail;
  }
}
